import React, { useState, useEffect } from 'react'
import { useTrans } from 'hooks'
import { gql, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { PRODOTTI_CORRELATI_CATEGORIA } from 'utils/queries'
import { Button, WContainer, JsonLd } from 'components/atoms'
import { BoxMarketing } from 'components/molecules'
import {
  AbbinamentiMenu,
  Page,
  ProdottiCorrelati,
  CantineHomeBox,
  TagCloud,
  ArticoliHome,
} from 'components/organisms'

import HeroSlider from 'components/organisms/HeroSlider/HeroSlider'
import Head from 'next/head'
import { DOMAIN } from 'utils/settings'
import { useRouter } from 'next/router'

interface Props {
  pagina?: any
}

const HomeTemplate = (props: Props) => {
  const { pagina = '' } = props
  const t = useTrans()
  const router = useRouter()

  const [prodottiPiuVenduti, setprodottiPiuVenduti] = useState([])
  const { data: dataConsigliati, loading: loadingConsigliati } = useQuery(
    gql`
      ${PRODOTTI_CORRELATI_CATEGORIA.replace('__chiave__', 'piu-venduti')}
    `
  )

  const { menu } = useSelector((state) => state.menu)
  const hpmenu = menu?.edges.find((item) => item.node.key === 'hp')

  const heroitems = hpmenu?.node.children.edges.find((item) => item.node.key === 'hero')?.node
  const tags = hpmenu?.node.children.edges.find((item) => item.node.key === 'tags')?.node
  const boxMarketing = hpmenu?.node.children.edges.find(
    (item) => item.node.key === 'box-marketing'
  )?.node

  useEffect(() => {
    if (!loadingConsigliati && dataConsigliati)
      setprodottiPiuVenduti(dataConsigliati.correlatiCategoria)
  }, [loadingConsigliati])

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    'name': 'Vinicum',
    'url': `${
      router.locale === 'en'
        ? `${DOMAIN}/${router.locale}${pagina['url']}`
        : `${DOMAIN}${pagina['url']}`
    }`,

    'logo': `${`${DOMAIN}/images/logo-vinicum-bianco.png`}`,
    'description':
      "Vinicum è un sito web dedicato al mondo del vino, che offre una vasta selezione di vini provenienti da cantine di prestigio in tutta Italia. Fondata con l'obiettivo di portare l'eccellenza enologica direttamente sulla tavola dei consumatori, Vinicum si distingue per la sua curata selezione di etichette, che spaziano dalle classiche denominazioni italiane ai vini di nicchia e alle novità del settore.",
    'address': {
      '@type': 'PostalAddress',
      'streetAddress': 'Villa Belvedere',
      'addressLocality': 'Calmasino di Bardolino',
      'addressRegion': 'VR',
      'postalCode': '37011',
      'addressCountry': 'IT',
    },
    'vatID': '03508110230',

    'contactPoint': {
      '@type': 'ContactPoint',
      'telephone': '+39 045 6269600',
      'email': 'support@vinicum.com',
    },
    'sameAs': ['https://www.instagram.com/vinicum/'],
  }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
      </Head>

      <Page pagina={pagina} hideHead childrenContainerVariant="none" className="home-template">
        <HeroSlider className="home-template__hero-slider" items={heroitems?.children?.edges} />
        <WContainer>
          {prodottiPiuVenduti.length > 0 && (
            <>
              <ProdottiCorrelati
                prodotti={prodottiPiuVenduti}
                title={t('I prodotti più venduti')}
              />
              <div className="home-template__prodotti-venduti__more">
                <Button
                  label={t('Tutti i prodotti più venduti')}
                  variant="secondary"
                  href="/i-vini/selezioni/piu-venduti" // @TODO deve diventare dinamico
                />
              </div>
            </>
          )}
          <div id="section-home-1"></div>
          <AbbinamentiMenu className="home-template__abbinamenti" />
          <div className="home-template__box-marketing">
            {boxMarketing?.children?.edges.map((item, key) => (
              <BoxMarketing {...item.node} key={`boxmarketing_${key}`} />
            ))}
          </div>
          <TagCloud
            title={t('Non sai cosa bere?')}
            description={t(
              'Non sai cosa scegliere? Lasciati ispirare dai  prodotti più ricercati dalla community Vinicum'
            )}
            tags={tags?.children?.edges}
            variant="fancy"
            className="home-template__tag-cloud"
          />
          <div id="section-home-2"></div>
          <CantineHomeBox className="home-template__box-cantine" />
        </WContainer>
        <ArticoliHome className="home-template__articoli" />
        <WContainer>
          <div id="section-home-3"></div>
        </WContainer>
      </Page>
    </>
  )
}

export default HomeTemplate
